<template>
  <div
    class="o-filter-promo"
    :class="{'o-filter-promo--disable': promoCount === 0}"
  >
    <ACheckboxToggle
      :value="isFilterActive"
      :title="promoFilterLabel"
      @input="eventChangeFilter()"
    />
    <span class="o-filter-promo__count">{{ promoCount }}</span>
  </div>
</template>

<script>
import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle';
import { attributes } from 'config';

export default {
  name: 'MCategoryFilterPromo',
  components: {
    ACheckboxToggle
  },
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    promoCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    promoFilter () {
      return attributes.filterIsPromo
    },
    promoFilterLabel () {
      return this.$t(attributes.filterIsPromo.label)
    },
    isFilterActive () {
      return this.$route.query.hasOwnProperty(this.promoFilter.type)
    }
  },
  methods: {
    eventChangeFilter () {
      this.$emit('promo-filter-changed', this.promoFilter)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-filter-promo {
  display: flex;
  margin-right: var(--spacer-sm);

  @include for-mobile {
    position: relative;
    margin: 0;
  }

  &__count {
    margin-top: var(--spacer-2);
    padding-left: var(--spacer-10);
    font-size: var(--font-size-14);
    color: var(--neutral-gray);

    @include for-mobile {
      margin-top: 0;
      font-weight: 500;
    }
  }

  &--disable {
    pointer-events: none;
    opacity: 0.7;
  }
}
</style>
