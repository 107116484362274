<template>
  <div id="search" class="search">
    <MBreadcrumbs />
    <ASearchPageResultEmpty
      v-if="isEmptySearchResult"
      :query="queryProp"
    />
    <div
      v-if="!isEmptySearchResult"
      class="section-head"
    >
      <SfHeading
        :level="3"
        class="section-head__title"
      >
        <template #title>
          <h1 class="sf-heading__title sf-heading__title--h3">
            <span class="sf-heading__title-text">
              {{ $t('Search results for') }}
              {{ `«${queryProp}»` }}
            </span>
            <small v-if="!getLoadingResultPage" class="section-head__total">
              {{ $tc('{count} items', totalProducts) }}
            </small>
          </h1>
        </template>
      </SfHeading>
      <!--      TODO Temporarily hide tabs-->
      <ASectionTabs
        v-if="false"
        class="section-head__tabs"
        :items="tabs"
        :active-tab="activeTab"
        data-transaction-name="Search - Set Active Tab"
      />
    </div>
    <div
      v-if="!isEmptySearchResult"
      class="main section"
    >
      <div class="search-products">
        <div class="sidebar">
          <MSidebarMobileButtons
            :categories-are-shown="categoriesAreShown"
            :filters-count="activeFiltersCount"
            @open="isFilterSidebarOpen = true"
            @toggle-categories="toggleCategoriesFilters()"
          />

          <MSearchFilterSearch
            :class="{'sidebar-hide--mobile': isDesktop ? false : !categoriesAreShown}"
            :categories="categories"
            :current-category="currentCategory"
            :categories-products-count="{}"
            :total-products="searchTotalProducts"
            :open-categories="openedCategories"
            :show="isDesktop ? false : !categoriesAreShown"
            @select="setCurrentCategory"
          />

          <div
            class="sidebar-filters desktop-only"
            v-if="Object.keys(availableFilters).length > 0 && Object.keys(filterLabels).length > 0"
          >
            <MSidebarFilter
              :link-builder="false"
              :available-filters="availableFilters"
              :key="Object.keys(availableFilters).length"
              :filter-labels="filterLabels"
              :actived-filters="getCurrentFilters"
              :price-from-filter="true"
              @change="changeFilterMultisearch"
              @submit-filter-price="submitFilterPrice"
            />
          </div>
        </div>
        <div class="main__content">
          <div class="products">
            <div class="navbar desktop-only">
              <div class="navbar__filter">
                <MCategoryFiltersSelected
                  v-if="activeFiltersCount !== 0 && getLoadedResultPage"
                  class="desktop-only"
                  :search-page="true"
                  :key="Object.keys(getCurrentFilters).length"
                  :filter-categories="getCurrentFilters"
                  :filter-labels="filterLabels"
                  @clear="changeFilterMultisearch"
                  @clear-all="clearAllFilters()"
                  :available-filters="availableFilters"
                />
              </div>
              <div class="navbar__sort">
                <SfSelect
                  class="navbar__select sf-select--white sort-by"
                  ref="SortBy"
                  :selected="sortOrder"
                  @change="changeSortOrder"
                >
                  <SfSelectOption
                    v-for="option in sortOptions"
                    :key="option.id"
                    :value="option.id"
                    class="sort-by__option"
                  >
                    {{ $t(option.label) }}
                  </SfSelectOption>
                  <template #icon>
                    <span class="dropdown-chevron" />
                  </template>
                </SfSelect>
              </div>
            </div>
            <ASearchResultEmpty
              v-if="isNoProducts && getLoadedResultPage && !getLoadingResultPage"
              :is-no-result-in-search="true"
              :is-no-result-in-city="true"
            />
            <template>
              <MCategoryList
                :is-loading="getLoadingResultPage && !isLoadMoreMode"
                :products="getSearchProducts"
                class-list="products__grid top-grid"
                :wishlist-icon="false"
              />

              <div
                class="bottom-plp-block"
                v-if="totalPages > 1"
              >
                <SfOPagination
                  class="products__pagination"
                  :current="currentPage"
                  :total="totalPages"
                  :count-range-pages="countRangePages"
                  :scroll-top="true"
                  @loadMoreFromPagEmit="loadMoreFromPagEmitFunction"
                />

                <ALoadingSpinner
                  v-show="getLoadingResultPage"
                  :size="24"
                  :weight="3"
                  :is-absolute-position="true"
                />
              </div>
            </template>
            <!--            <MPopularCategories v-if="false" />-->
          </div>
        </div>
      </div>
    </div>
    <MRecentlyViewedProducts
      :title-heading="$t('Viewed')"
      is-centered
    />
    <MAdvantages />
    <OSection
      v-if="false"
      :title-heading="$t('Cook with VARUS')"
    >
      <template #link>
        <SfLink link="#" target="_blank" class="sf-link--primary">
          {{ $t('Read recipes') }}
        </SfLink>
      </template>
      <MRecipeCarousel class="flex" />
    </OSection>
    <MSidebarMobileFilters
      :key="Object.keys(getCurrentFilters).length"
      :is-open="!!isFilterSidebarOpen"
      @close="isFilterSidebarOpen = false"
      @open="filterSidebarInner"
      @clear="clearAllFilters()"
      :sort-label="sortLabel"
      :is-search="true"
      :filter-labels="filterLabels"
      :filters-count="activeFiltersCount"
      :available-filters="availableFilters"
      :filter-actived="getCurrentFilters"
      :filter-price="getFilterPrice"
      :search-page="true"
    />
    <MSidebarFiltersInner
      :is-open="!!filterSidebarInnerOpen"
      :filter-name="filterSidebarInnerOpen"
      @close="onCloseFilter"
      @current="currentFilter"
      @change-sort-order="changeSortOrderValue"
      @change="changeFilterMultisearch"
      @submit-filter-price="submitFilterPrice"
      :sort-options="sortOptions"
      :filter-labels="filterLabels"
      :available-filters="availableFilters"
      :actived-filters="getCurrentFilters"
      :sort-order="sortOrder"
      :is-search-page="true"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers';
import DeviceType from 'theme/mixins/DeviceType';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks';
import { SfHeading, SfLink, SfSelect } from '@storefront-ui/vue';
import ASectionTabs from 'theme/components/atoms/a-section-tabs';
import OSection from 'theme/components/organisms/o-section';
import MRecipeCarousel from 'theme/components/molecules/m-recipe-carousel';
import MSidebarFiltersInner from 'theme/components/molecules/m-sidebar-filters-inner';
import MSidebarMobileButtons from '../components/molecules/m-sidebar-mobile-buttons';
import MSidebarMobileFilters from '../components/molecules/m-sidebar-mobile-filters';
import MAdvantages from 'theme/components/molecules/m-advantages';
import i18n from '@vue-storefront/i18n';
import ASearchResultEmpty from '../components/atoms/a-search-result-empty';
import MBreadcrumbs from 'theme/components/molecules/m-breadcrumbs';
import SfOPagination from 'theme/components/storefront-override/SfOPagination';
import MSearchFilterSearch from 'theme/components/molecules/m-search-filter-search';
import MRecentlyViewedProducts from 'theme/components/molecules/m-recently-viewed-products';
import MCategoryList from 'theme/components/molecules/m-category-list';
import GoogleTagManager from 'theme/mixins/gtm';
import { metaSearch } from '../meta/search';
import Multisearch from '../mixins/Multisearch';
import MSidebarFilter from 'theme/components/molecules/m-sidebar-filter.vue';
import MCategoryFiltersSelected from 'theme/components/molecules/m-category-filters-selected.vue';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';
import ASearchPageResultEmpty from 'theme/components/atoms/a-search-page-result-empty.vue';

let isLoadMore = false;

const THEME_PAGE_SIZE = 40;

const composeInitialPageState = async (
  store
  // route
) => {
  try {
    const currentCategory = store.getters['search-result/getCurrentCategory'];

    catalogHooksExecutors.categoryPageVisited(currentCategory);

    return !store.getters['shipping/isLoadedShippingDetails']
  } catch (e) {
    return false
  }
};

export default {
  name: 'SearchPage',
  components: {
    ASearchPageResultEmpty,
    ALoadingSpinner,
    MCategoryFiltersSelected,
    MSidebarFilter,
    MSearchFilterSearch,
    ASearchResultEmpty,
    MCategoryList,
    MAdvantages,
    ASectionTabs,
    MSidebarMobileFilters,
    MSidebarMobileButtons,
    MSidebarFiltersInner,
    MBreadcrumbs,
    SfSelect,
    SfHeading,
    SfLink,
    OSection,
    MRecipeCarousel,
    SfOPagination,
    MRecentlyViewedProducts
  },
  mixins: [
    DeviceType,
    GoogleTagManager,
    Multisearch
  ],
  props: {
    queryProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoadMoreMode: false,
      currentPage: 1,
      isFilterSidebarOpen: false,
      filterSidebarInnerOpen: '',
      activeTab: 'Products',
      countRangePages: [],
      page: 1,
      tabs: [
        { name: 'Products', count: 6 }
      ],
      search: '',
      categoriesAreShown: false,
      filterActived: new Map(),
      filterSort: null,
      openedCategories: [],
      currentFilterCategories: []
    };
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: 'search-result/getCurrentCategory',
      getCurrentFilters: 'search-result/getCurrentFilters',
      getSearchProducts: 'search-result/getSearchProducts',
      getLoadingResultPage: 'search-result/getLoadingResultPage',
      getLoadedResultPage: 'search-result/getLoadedResultPage',
      categories: 'search-result/getCategories',
      totalPages: 'search-result/getTotalPages',
      totalProducts: 'search-result/getTotalProducts',
      getSearchTotalProducts: 'search-result/getSearchTotalProducts',
      currentShipping: 'shipping-module/getCurrent',
      isLoadedShippingDetails: 'shipping-module/getShippingLoaded',
      getCurrentUser: 'userInfo/getCurrentUser',
      getFilterPrice: 'search-result/getFilterPrice',
      availableFilters: 'search-result/getAvailableFilters',
      filterLabels: 'search-result/getFilterLabels',
      getDefaultShop: 'shipping-module/getDefaultShop'
    }),
    activeFiltersCount () {
      return Object.keys(this.getCurrentFilters).length
    },
    isLoadMore () {
      return this.currentPage < this.totalPages
    },
    isEmptySearchResult () {
      return this.totalProducts === 0 && !this.activeFiltersCount && this.getLoadedResultPage && !this.getLoadingResultPage
    },
    isNoProducts () {
      return this.getSearchProducts.length === 0
    },
    searchTotalProducts () {
      return (this.categories || [])?.reduce((a, c) => (
        a + (c.countProduct || 0)
      ), 0)
    },
    sortOrder () {
      return (
        this.filterSort || this.sortOptions?.[0]?.id
      );
    },
    sortLabel () {
      const selectedSortOrder = this.sortOptions.find(sortOption => sortOption.id === this.sortOrder) || {}
      return selectedSortOrder.label || ''
    },
    isUserHasAddress () {
      return Boolean(this.getCurrentUser.addresses.length);
    },
    currentCategory () {
      if (this.$route.query.hasOwnProperty('cat')) {
        return Number(this.$route.query.cat);
      }

      return undefined
    },
    isReady () {
      return this.currentShipping?.shop?.id || this.getDefaultShop?.id
    }
  },
  watch: {
    sortOrder () {
      if (this.currentPage > 1) {
        this.changePage();
      }
    },
    $mq: {
      immediate: true,
      handler () {
        this.categoriesAreShown = this.isDesktop;
      }
    },
    queryProp: {
      immediate: true,
      handler (val, oldVal) {
        if (val === oldVal) return

        this.setBreadcrumbs(val)

        if (!this.isReady || !oldVal) return

        this.clearAllFilters()
        this.requestSearchResults()
      }
    },
    'currentShipping.shop.id': {
      handler: async function (newValue, oldVal) {
        if (!this.isLoadedShippingDetails || newValue === oldVal) return

        this.applyFilters(this.$route.query)
        await this.requestSearchResults()
      },
      immediate: true
    },
    'isLoadedShippingDetails': {
      handler: async function (newValue) {
        if (!newValue || !this.getDefaultShop?.id) return

        this.applyFilters(this.$route.query)
        await this.requestSearchResults()
      },
      immediate: true
    }
  },
  async asyncData ({ store, context }) {
    if (isLoadMore) {
      isLoadMore = false;
      return;
    }

    if (context) context.output.cacheTags.add('search')

    const toPromise = [
      store.dispatch('homepage/loadAdvantagesContent'),
      !context ? composeInitialPageState(store) : null, // route
      store.dispatch('search-result/setSearchMetaTag', {}),
      store.dispatch('ui/loadCatalogMenu')
    ].filter(Boolean);

    try {
      await Promise.allSettled(toPromise);
    } catch (error) {
      console.error('Error in asyncData:', error);
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.applyFilters(vm.$route.query || {})
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.gtmProductsHandler(this.getSearchProducts, 'view_item_list')
    this.gtmProductsHandlerAds(this.getSearchProducts, 'view_search_results_ads')

    if (isServer || to?.params?.loadMore || !['search', 'ru-search'].includes(to?.name)) return next()

    this.applyFilters(to?.query || {})
    this.loadSearchResults()

    next()
  },
  async mounted () {
    this.categoriesAreShown = !(this.isMobile || this.isTablet)
    this.gtmProductsHandler(this.getSearchProducts, 'view_item_list')
    this.gtmProductsHandlerAds(this.getSearchProducts, 'view_search_results_ads')
    this.selectedCategory();
  },
  beforeDestroy () {
    this.setCurrentCategory(null)
  },
  methods: {
    ...mapActions('search-result', {
      loadSearch: 'loadSearch',
      setCategory: 'setCategory',
      setSearchFilter: 'setSearchFilter',
      resetFilterSearch: 'resetFilterSearch'
    }),
    async submitFilterPrice (
      minPrice,
      maxPrice
    ) {
      const filter = {
        id: `price`,
        type: 'price',
        from: Number(minPrice),
        to: Number(maxPrice)
      };

      this.changeFilterMultisearch({ filter, active: false })
    },
    selectedCategory () {
      if (this.$route.query.hasOwnProperty('cat')) {
        let categories = []
        let categoryIds = this.$route.query.cat
        if (Array.isArray(categoryIds)) {
          categories = this.categories.filter(category => {
            return categoryIds.some(categoryId => {
              return category.id === Number(categoryId)
            })
          })
        } else {
          categories = this.categories.filter(category => {
            return category.id === Number(categoryIds)
          })
        }

        if (!categories.length) return false

        let openedCategoryArray = []

        if (categories[0]?.name && categories[0]?.parent_id !== 2) {
          openedCategoryArray.push(categories[0].name)
          let subParentCategory = this.categories.filter(category => {
            return category.id === categories[0].parent_id
          })
          if (subParentCategory?.[0] && subParentCategory[0].parent_id !== 2) {
            let parentCategory = this.categories.filter(category => {
              return category.id === subParentCategory[0].parent_id
            })
            openedCategoryArray.push(parentCategory[0].name)
          }
          openedCategoryArray.push(subParentCategory[0].name)
        } else {
          openedCategoryArray.push(categories[0].name)
        }

        this.currentFilterCategories = categories[0]
        this.openedCategories = openedCategoryArray
      }
    },
    setBreadcrumbs (query) {
      const routes = []

      this.$store.dispatch('breadcrumbs/set', {
        current: `${i18n.t('Search results for')} «${query}»`,
        routes: routes
      }, { root: true })
    },
    initPagination () {
      this.currentPage = +this.$route?.query?.page || 1;
      this.countRangePages = [];
    },
    async changeSortOrder (sortOrder) {
      this.filterSort = sortOrder

      await this.requestSearchResults(false)
    },
    onCloseFilter () {
      this.filterSidebarInnerOpen = ''
      this.requestSearchResults(false)
    },
    changeSortOrderValue (sortOrder) {
      this.filterSort = sortOrder
    },
    changeFilterMultisearch ({ filter, active }) {
      this.setSearchFilter({ filter, active })
      this.currentPage = 1
      this.requestSearchResults(false)
    },
    applyFilters (routerQuery) {
      const query = { ...routerQuery }

      if (query.sort) this.filterSort = query.sort
      this.currentPage = isNaN(query.page) ? 1 : +query.page

      delete query.cat
      delete query.q
      delete query.page
      delete query.sort

      this.resetFilterSearch()

      const toRequire = Object.keys(query)

      for (const key of toRequire) {
        if (key === 'price') {
          const [from, to] = (query?.[key]?.split('-') || [])

          const filter = {
            'id': 'price',
            'type': 'price',
            'from': from,
            'to': to
          }

          this.setSearchFilter({ filter, active: false })
          continue
        }

        const values = query?.[key] && Array.isArray(query[key]) ? query[key] : [query?.[key]]

        for (const value of values) {
          const filter = {
            'id': value,
            'label': value,
            'type': key,
            'count': 1
          }

          this.setSearchFilter({ filter, active: false })
        }
      }
    },
    changeFilter (filter, filterType) {
      const filterCurrent = filter
      filterCurrent.attribute_code = filter.type

      if (filterType === 'price') {
        filter['attribute_code'] = filterType
      }

      this.$store.dispatch('category-extension/switchSearchFilters', [filter]);
    },
    clearAllFilters () {
      this.filterActived.clear()
      this.currentPage = 1

      this.isFilterSidebarOpen = false
      this.resetFilterSearch()
      this.requestSearchResults(false)
    },
    filterSidebarInner (filter) {
      this.filterSidebarInnerOpen = filter
    },
    applyFilter (filter) {
      if (filter.name === 'sort') {
        this.changeSortOrder(filter.value)
      } else {
        this.changeFilter(filter.value, filter.name)
      }
    },
    async changePage (page) {
      const start = (page - 1) * THEME_PAGE_SIZE;

      if (
        start < 0 ||
        start >= this.getSearchTotalProducts ||
        this.getSearchTotalProducts < THEME_PAGE_SIZE
      ) {
        return;
      }

      this.currentPage = +page;
    },
    async setCurrentCategory (category) {
      this.clearAllFilters(false);
      this.toggleCategoriesFilters();
      const query = { q: this.$route.query.q };

      if (category !== null) {
        query.cat = category.id;
      }

      if (this.$route?.query?.page) {
        query.page = this.$route.query.page;
      }

      if (this.$route?.query?.sort) {
        query.sort = this.$route.query.sort;
      }

      await this.setCategory(category)
      await this.requestSearchResults(false)

      await this.$router.push({ query })
    },
    async setFilterQuery (filters) {
      let query = {};

      if (this.$route.query?.q) query.q = this.$route.query.q
      if (this.$route.query?.cat) query.cat = this.$route.query.cat
      if (this.$route.query?.page && this.currentPage > 1) query.page = this.currentPage

      if (this.filterSort && this.filterSort !== this.sortOptions?.[0]?.id) {
        query.sort = this.filterSort
      }

      const toQuery = Object.keys(filters).reduce((a, c) => {
        if (!filters[c].length) return a

        if (c === 'price') {
          a[c] = `${filters[c]?.[0]?.from}-${filters[c]?.[0]?.to}`

          return a
        }

        a[c] = filters[c].map(i => i.id)

        return a
      }, {})

      query = {
        ...query,
        ...toQuery
      }

      await this.$router.push({ query })
    },
    toggleCategoriesFilters () {
      this.categoriesAreShown = !this.categoriesAreShown
    },
    async loadMoreFromPagEmitFunction () {
      try {
        this.isLoadMoreMode = true;
        isLoadMore = true;

        this.currentPage += 1;
        this.countRangePages.push(this.currentPage);

        const query = { ...this.$route.query, page: this.currentPage }

        await this.$router.push({ query, params: { loadMore: true } });

        await this.loadSearch({
          route: this.$route,
          search: this.queryProp,
          size: THEME_PAGE_SIZE,
          category: this.$route.query?.cat,
          page: this.currentPage,
          sort: this.sortOrder,
          isLoadMore: true
        });
      } finally {
        this.isLoadMoreMode = false;
      }
    },
    currentFilter (filter) {
      this.filterActived = filter
    },
    async requestSearchResults (load = true) {
      try {
        if (isServer || !this.isReady) return

        await this.setFilterQuery(this.getCurrentFilters)

        if (!load) return

        await this.loadSearchResults()
      } finally {}
    },
    async loadSearchResults () {
      try {
        await this.loadSearch({
          category: this.getCurrentCategory?.id || this.$route.query.cat,
          pageSize: THEME_PAGE_SIZE,
          search: this.$route.query.q,
          sort: this.sortOrder,
          page: this.currentPage || 1,
          filters: this.getCurrentFilters
        });
      } finally {}
    }
  },
  metaInfo: metaSearch
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.search {
  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;

  .a-search-page-result-empty {
    @include for-desktop {
      padding-bottom: px2rem(334);
    }

    ::v-deep .sf-heading__subtitle {
      color: var(--black);
      font-size: var(--font-sm);

      @include for-desktop {
        font-size: var(--font-base);
      }

      .sf-link--primary {
        font-size: var(--font-sm);

        @include for-desktop {
          font-size: var(--font-base);
        }
      }
    }
  }

  .m-advantages {
    padding-top: 0;
  }

  &-products {
    display: block;

    @include for-desktop {
      display: flex;
    }
  }

  &-recipes {
    padding: 0;

    @include for-desktop {
      padding: 0 var(--spacer-sm);
      width: 100%;
    }

    &__item {
      width: calc(50% - var(--spacer-10));
      margin: var(--spacer-5) var(--spacer-5) 2.188rem var(--spacer-5);

      @include for-desktop {
        margin: var(--spacer-5) var(--spacer-5) 3.313rem var(--spacer-5);
        width: calc(25% - var(--spacer-10));
      }
    }

    &__list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: calc(-1 * var(--spacer-5)) calc(-1 * var(--spacer-5)) calc(-1 * var(--spacer-5));
      width: calc(100% + var(--spacer-10));
    }

    .products__pagination {
      margin-top: 0;
    }
  }
  .m-recently-viewed-products {
    ::v-deep {
      .o-section {
        &--center,
        &__content {
          padding: 0;
        }
        &__heading {
          @include for-mobile {
            padding: 0 var(--spacer-10) var(--spacer-15) var(--spacer-10);
          }

          @include for-tablet {
            padding: 0 var(--spacer-16) var(--spacer-15) 0;
          }
        }
      }
      .sf-heading__title--h3 {
        font-size: var(--font-size-18);

        @media only screen and (min-width: $tablet-min) {
          font-size: var(--font-size-32);
        }
      }
    }
  }
}

.main {
  display: flex;
  flex-direction: column;

  @include for-desktop {
    flex-direction: row;
  }
  &.section {
    padding: 0 var(--spacer-10);
  }

  &__content {
    box-sizing: border-box;
    flex: 1;
    margin: 0;
  }
}

.section {
  &-head {
    padding: 0 var(--spacer-10);
    margin: 0 0 var(--spacer-20);
    @media only screen and (min-width: $tablet-min) {
      margin: 0 0 var(--spacer-30);
    }

    &__title {
      display: flex;
      align-items: center;
      text-align: left;
      padding-left: 0;
    }

    &__total{
      color: var(--dark-gray);
    }
    &__tabs{
      padding: var(--spacer-sm) 0 0 0;
      border-bottom: 1px solid var(--line-color);
    }
  }
}

.breadcrumbs {
  padding: var(--spacer-base) var(--spacer-base) var(--spacer-base) var(--spacer-sm);

  ::v-deep .sf-breadcrumbs__list-item {
    &:not(:first-child)::before {
      content: '';
      background-image: url('~theme/assets/product-cart/dash.svg');
      background-repeat: no-repeat;
      background-position: 4px 11px;
      padding: 0 8px;
    }

    a {
      color: var(--dark-gray);
    }
    &:first-child a {
      color: var(--black);
    }
  }
}

::v-deep .sf-menu-item {
  &__count {
    margin: 0 0 0 var(--spacer-10);
    color: var(--dark-gray)
  }
}

.navbar {
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include for-desktop {
    padding: 0 0 var(--spacer-10) 0;
    border-bottom: 1px solid var(--line-gray);
  }

  &__filter {
    display: flex;
    grid-area: filter;
  }

  &__sort {
    .sort-by {
      white-space: nowrap;
    }

    .sf-select-option--is-active {
      color: var(--c-primary);
      pointer-events: none;
    }
  }

  &__select {
    --select-padding: 0 var(--spacer-lg) 0 var(--spacer-2xs);
    --select-margin: 0;
    --select-selected-padding: 0 var(--spacer-xl) 0 0;

    ::v-deep .sf-select-option {
      font-size: var(--font-sm);
    }

    .dropdown-chevron {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.sort-by {
  --select-dropdown-z-index: 3;
  flex: unset;
  padding: 0;

  ::v-deep {
    .sf-select__dropdown {
      left: initial;
      min-width: max-content;
      right: 0;
    }

    .sf-select-option {
      cursor: pointer;
    }
  }

  // This is needed to hide SfSelect main element on mobile view.
  // This code can be removed when SfSelect supports better customization.
  @include for-mobile {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    --select-dropdown-z-index: 2;

    ::v-deep .sf-select__cancel {
      margin: 16px;
      box-shadow: 4px 12px 24px rgba(119, 121, 122, 0.25);
      --button-width: calc(100% - 32px);
    }
  }
}

.sidebar {
  flex: 100%;

  @include for-desktop {
    padding: 0;
    flex: 0 0 13.75rem;
    border: none;
    margin-right: var(--spacer-20);
    max-width: 13.75rem;
  }

  &-filters {
    background: var(--light-gray);
    border-radius: 10px;
    padding: var(--spacer-sm) var(--spacer-20);
    margin-top: var(--spacer-10);
    --sidebar-title-display: none;
    --sidebar-top-padding: 0;

    @include for-desktop {
      --sidebar-content-padding: 0 var(--spacer-xl);
      --sidebar-bottom-padding: 0 var(--spacer-xl);
    }
  }
}

::v-deep .sf-bar {
  --bar-background: transparent;
  border-bottom: 2px solid var(--light-gray);

  &__buttons {
    display: flex;
  }

  &__title{
    font-size: var(--font-size-20);
  }

  &__clear {
    font-size: var(--font-sm);
    color: var(--orange);
    font-weight: var(--font-normal);
    padding-right: var(--spacer-base);
  }

  &__close{
    padding: var(--spacer-xs);
    background-color: var(--light-gray);
    top: var(--spacer-xs);
    border-radius: 5px;
    .sf-icon.size-xxs {
      --icon-size: .88rem;
    }
  }

  &--filters {
    > div:first-child {
      display: none;
    }
  }
}

.list {
  --menu-item-font-size: var(--font-sm);

  &__item {

    &:not(:last-of-type) {
      --list-item-margin: 0 0 var(--spacer-sm) 0;
    }
  }
}

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;

  ::v-deep &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep &__grid {
    padding-top:var(--spacer-20);
    @include for-desktop {
      margin: 0 calc(-1 * var(--spacer-5));
      ::v-deep .sf-product-card {
        &.sf-product-card--out-of-stock-container:hover:before {
          bottom: calc(-1 * var(--spacer-5));
        }
        &:hover {
          &:before {
            bottom: calc(-1 * var(--spacer-35));
          }
          .sf-product-card__hidden-basket {
            position: absolute;
          }
        }
        .sf-product-card__hidden-basket {
          position: absolute;
          left:5px;
          bottom: -15px;
        }
      }
    }
  }

  &__slide-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  &__slide-enter-active {
    transition: all 0.2s ease;
    transition-delay: calc(0.1s * var(--index));
  }

  @include for-desktop {
    margin: 0;

    &__pagination {
      display: flex;
      justify-content: center;
      margin: 0;
      background-color: var(--light-gray);
      padding: var(--spacer-20);
    }

    ::v-deep .m-category-list__item {
      flex: 1 1 25%;
    }
  }
}

.filters {

  &__title {
    --heading-title-font-size: var(--font-xl);
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;

    &:first-child {
      margin: calc(var(--spacer-xl) + var(--spacer-base)) 0 var(--spacer-xs) 0;
    }
  }

  &__color {
    margin: var(--spacer-xs) var(--spacer-xs) var(--spacer-xs) 0;
    border: 1px solid var(--c-light);
  }

  &__item {
    --filter-label-color: var(--c-secondary-variant);
    --filter-count-color: var(--c-secondary-variant);
    --checkbox-padding: 0 var(--spacer-sm) 0 var(--spacer-xl);
    padding: var(--spacer-sm) 0;
    border-bottom: 1px solid var(--c-light);

    &:last-child {
      border-bottom: 0;
    }

    @include for-desktop {
      --checkbox-padding: 0;
      margin: var(--spacer-sm) 0;
      border: 0;
      padding: 0;
    }
  }

  &__accordion-item {
    --accordion-item-content-padding: 0;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
  }

  &__buttons {
    margin: var(--spacer-sm) 0;
  }

  &__button-clear {
    --button-background: var(--c-light);
    --button-color: var(--c-dark-variant);
    margin: var(--spacer-xs) 0 0 0;
  }
}

.sf-heading__title-text {
  margin-right: px2rem(20);
  ::v-deep {
    @include header-title;
  }
}

.section-head__total {
  font-size: var(--font-size-13);
}

.section-head {
  padding: 0 var(--spacer-sm);
}

.search-products {
  width: 100%;
  z-index: 0;

  ::v-deep .m-filter-categories {
    .accordion {
      border-radius: var(--spacer-10);
      max-height: px2rem(340);
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color:  rgba(235, 103, 71, 0.3);

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(235, 103, 71, 0.3);
      }

      &-item__header {
        white-space: normal;
      }
    }
  }

  ::v-deep .sidebar {
    @include for-desktop {
      max-width: px2rem(220);
    }
  }
}

.bottom-plp-block {
  background-color: var(--light-gray);
  margin-left: calc(-1 * var(--spacer-10));
  margin-right: calc(-1 * var(--spacer-10));
  width: calc(100% + calc(2 * var(--spacer-10)));
  padding-top: var(--spacer-40);
  padding-bottom: var(--spacer-5);
  position: relative;

  @media (min-width: $tablet-min) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: var(--spacer-5);
  }
  @include for-desktop {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: var(--spacer-5);
  }

  .sf-link--primary {
    font-size: var(--font-sm);
    padding: var(--spacer-20) 0 0;
    background-color: transparent;
    align-self: center;

    span {
      color: var(--orange);
      font-weight: var(--font-normal);
    }

    @media (min-width: $tablet-min) {
      padding-top: 0;
      padding-left: var(--spacer-30);
    }
  }

  .sf-pagination {
    z-index: 1;

    @include for-desktop {
      margin-top: 0;
    }

    @media (min-width: $tablet-min) {
      padding-bottom: var(--spacer-20);
      padding-top: var(--spacer-20);
      align-items: center;
    }

    ::v-deep {
      .router-link-active,
      .sf-pagination__item {
        color: var(--orange);
      }
      .sf-pagination__item--current {
        color: var(--black);
      }
      .sf-pagination__item:not(.sf-pagination__item--current):not(.sf-link) {
        color: var(--black);
      }
      .sf-pagination__item {
        &--next--disable,
        &--prev--disable {
          &:after {
            border-color: var(--dark-gray);
          }
        }
      }
    }
  }
}

::v-deep .m-recently-viewed-products {
  padding-top: var(--spacer-50);

  .o-section__content .sf-carousel .glide__slide {
    @include for-desktop {
      margin-bottom:var(--spacer-30);
    }
  }

  @include for-tablet {
    padding-top: var(--spacer-60);
  }

  @include for-desktop {
    padding-top: var(--spacer-2xl);
  }

  .o-section--center {
    padding: 0 var(--spacer-10);
  }

  .o-section--slider {
    padding-top: 0;
  }
}

::v-deep .m-filter-categories {
  .sf-accordion.accordion {
    @include for-desktop {
      max-height: 100%;
    }
  }
}
.sidebar-hide--mobile {
  display: none;
}

#search {
  ::v-deep .section-head__total {
    @include only-mobile {
      display: block;
    }
  }

  ::v-deep .a-loading-spinner--absolute {
    z-index: 2;
  }
}

.m-filter-categories {
  ::v-deep {
    .sf-accordion.accordion {
      max-height: 21.25rem;
      overflow-y: scroll;
      border-radius: var(--spacer-10);
      @include for-desktop {
        .accordion-item {
          &__header:hover {
            color: var(--orange);
          }
        }
      }
    }
  }
}
</style>
