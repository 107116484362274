<template>
  <div class="o-filter-stock">
    <ACheckboxToggle
        :value="isFilterActive"
        :title="stockFilterLabel"
        @input="handleFilterChange"
    />
  </div>
</template>

<script>
import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle';
import { attributes, products } from 'config';

export default {
  name: 'MCategoryFilterStock',
  components: {
    ACheckboxToggle
  },
  computed: {
    stockFilter () {
      return attributes.filterIsOnStock;
    },
    stockFilterLabel () {
      return this.$t(attributes.filterIsOnStock.label);
    },
    stockFilterValues () {
      return products.stockFilterValues;
    },
    isFilterActive () {
      return this.$route.query.hasOwnProperty(this.stockFilter.type);
    }
  },
  methods: {
    async handleFilterChange (value) {
      const filter = {
        type: this.stockFilter.type,
        id: this.stockFilterValues[value]
      }
      this.$emit('stock-filter-changed', filter);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-filter-stock {
  display: flex;
  margin-right: var(--spacer-sm);

  @include for-mobile {
    position: relative;
    margin: 0;
  }
}
</style>
